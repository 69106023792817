<template>
    <div class="monitoring-data">
        <div class="monitoring-item">
            <label>创建名称</label>
            <el-input v-model="merchants_realTime_monitoring_data.name" style="width: 420px" minlength="2" maxlength="18"
                      placeholder="2-18个字"></el-input>
        </div>
        <div class="monitoring-item">
            <label>商品类目</label>
            <el-cascader v-model="merchants_realTime_monitoring_data.category_id" class="w420-cascader"
                         :options="goodsClassList" :props="keyObjClass" clearable @change="handleChangeGoodsClass">
            </el-cascader>
        </div>
        <div class="main-wrapper">
            <div class="optimize-title">商家实时监控数据</div>
            <el-form :model="merchants_realTime_monitoring_data" ref="merchants_realTime_monitoring_data"
                     :rules="monitoringDataRules" class="search-ad-form">
                <div class="monitoring-info">
                    <div class="monitoring-input-title">
                        <span class="text">咨询量</span>
                        <span class="text">接待量</span>
                        <span class="text">促成下单金额</span>
                        <span class="text">促成下单商品数</span>
                    </div>
                    <div class="search-column">
                        <div class="search-column-one">
                            <div class="search-input"
                                 v-for="(inquiriesItem,inquiriesIndex) in merchants_realTime_monitoring_data.inquiries"
                                 :key="inquiriesIndex">
                                <el-form-item :label="inquiriesItem.label" label-width="96px" label-position="left"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}" class="search-ad-form-item item-top">
                                    <el-input-number :ref="`${inquiriesItem.title}_${inquiriesIndex}`" :controls="false" @blur="isSaveFun"
                                                     v-model="inquiriesItem.type_value" :min="0" :max="9999999999" :precision="0" style="width: 160px">
                                    </el-input-number>
                                </el-form-item>
                                <el-form-item label="波动范围" label-width="96px" label-position="left"
                                              class="search-ad-form-item item-bottom" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number class="item-input" :min="-99" :max="100" :controls="false" :precision="0" @blur="isSaveFun"
                                                     v-model="inquiriesItem.value1" :ref="`${inquiriesItem.title}_${inquiriesIndex}`"
                                                     style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 4px;">~</span>
                                    <el-input-number v-model="inquiriesItem.value2" :ref="`${inquiriesItem.title}_${inquiriesIndex}`" @blur="isSaveFun"
                                                     :min="-99" :max="100" :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;">
                            <div class="search-input"
                                 v-for="(accommodationItem,accommodationIndex) in merchants_realTime_monitoring_data.accommodation_was"
                                 :key="accommodationIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number v-model="accommodationItem.type_value" :controls="false" :max="9999999999" :min="0"
                                                     :precision="0" :ref="`${accommodationItem.title}_${accommodationIndex}`" @blur="isSaveFun"
                                                     style="width: 160px;margin: 0 3px;">
                                    </el-input-number>
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number v-model="accommodationItem.value1" :max="9999999999" :min="0" :precision="0"
                                                     :controls="false" :ref="`${accommodationItem.title}_${accommodationIndex}`" @blur="isSaveFun"
                                                     style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number v-model="accommodationItem.value2" :controls="false" :max="9999999999" :min="0" @blur="isSaveFun"
                                                     :precision="0" :ref="`${accommodationItem.title}_${accommodationIndex}`"
                                                     style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;">
                            <div class="search-input"
                                 v-for="(promotedItem, promotedIndex) in merchants_realTime_monitoring_data.promoted_order_amount"
                                 :key="promotedIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${promotedItem.title}_${promotedIndex}`" v-model="promotedItem.type_value" @blur="isSaveFun"
                                                     :controls="false" :max="9999999999" :min="0" :precision="0" style="width: 160px;margin: 0 3px;">
                                    </el-input-number>
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${promotedItem.title}_${promotedIndex}`" v-model="promotedItem.value1" @blur="isSaveFun"
                                                     :max="9999999999" :min="0" :controls="false" :precision="0" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number :ref="`${promotedItem.title}_${promotedIndex}`" v-model="promotedItem.value2" @blur="isSaveFun"
                                                     :controls="false" :max="9999999999" :min="0" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;margin-right: 0;">
                            <div class="search-input"
                                 v-for="(numItem, numIndex) in merchants_realTime_monitoring_data.promoted_order_num" :key="numIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number v-model="numItem.type_value" :controls="false" :max="9999999999" :min="0" @blur="isSaveFun"
                                                     :precision="0" :ref="`${numItem.title}_${numIndex}`" style="width: 160px;margin: 0 3px;">
                                    </el-input-number>
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${numItem.title}_${numIndex}`" v-model="numItem.value1" :max="9999999999"  @blur="isSaveFun"
                                                     :min="0" :controls="false" :precision="0" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number :ref="`${numItem.title}_${numIndex}`" v-model="numItem.value2" :max="9999999999"  @blur="isSaveFun"
                                                     :min="0" :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="monitoring-info">
                    <div class="monitoring-input-title">
                        <span class="text">应答率</span>
                        <span class="text">30s应答率</span>
                        <span class="text">评价率</span>
                        <span class="text">解决率</span>
                    </div>
                    <div class="search-column">
                        <div class="search-column-one">
                            <div class="search-input" v-for="(rateItem,rateIndex) in merchants_realTime_monitoring_data.response_rate"
                                 :key="rateIndex">
                                <el-form-item :label="rateItem.label" label-width="96px" label-position="left"
                                              class="search-ad-form-item item-top" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${rateItem.title}_${rateIndex}`" :controls="false" @blur="isSaveFun"
                                                     v-model="rateItem.type_value" :min="0" :max="100" :precision="0" style="width: 160px">
                                    </el-input-number>%
                                </el-form-item>
                                <el-form-item label="波动范围" label-width="96px" label-position="left"
                                              class="search-ad-form-item item-bottom" :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${rateItem.title}_${rateIndex}`" class="item-input" :min="-99" :max="100" @blur="isSaveFun"
                                                     :controls="false" :precision="0" v-model="rateItem.value1" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 4px;">~</span>
                                    <el-input-number :ref="`${rateItem.title}_${rateIndex}`" v-model="rateItem.value2" :min="-99" :max="100" @blur="isSaveFun"
                                                     :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;">
                            <div class="search-input"
                                 v-for="(ratesItem,ratesIndex) in merchants_realTime_monitoring_data.response_rate_30" :key="ratesIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${ratesItem.title}_${ratesIndex}`" v-model="ratesItem.type_value" :min="0" :max="100" @blur="isSaveFun" :controls="false" :precision="0" style="width: 160px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${ratesItem.title}_${ratesIndex}`" v-model="ratesItem.value1" :min="-99" :max="100" @blur="isSaveFun"
                                                     :controls="false" :precision="0" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number :ref="`${ratesItem.title}_${ratesIndex}`" v-model="ratesItem.value2" :min="-99" :max="100" @blur="isSaveFun"
                                                     :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;">
                            <div class="search-input"
                                 v-for="(evaluationItem,evaluationIndex) in merchants_realTime_monitoring_data.evaluation_rate"
                                 :key="evaluationIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${evaluationItem.title}_${evaluationIndex}`" @blur="isSaveFun"
                                                     v-model="evaluationItem.type_value" :min="0" :max="100" :controls="false" :precision="0"
                                                     style="width: 160px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${evaluationItem.title}_${evaluationIndex}`" v-model="evaluationItem.value1"  @blur="isSaveFun"
                                                     :min="-99" :max="100" :controls="false" :precision="0" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number :ref="`${evaluationItem.title}_${evaluationIndex}`" v-model="evaluationItem.value2" @blur="isSaveFun"
                                                     :min="-99" :max="100" :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                        <div class="search-column-one" style="width: 214px;margin-right: 0;">
                            <div class="search-input"
                                 v-for="(resolutionItem,resolutionIndex) in merchants_realTime_monitoring_data.resolution_rate"
                                 :key="resolutionIndex">
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-top"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${resolutionItem.title}_${resolutionIndex}`" @blur="isSaveFun"
                                                     v-model="resolutionItem.type_value" :min="0" :max="100" :controls="false" :precision="0"
                                                     style="width: 160px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                                <el-form-item label="" label-width="54px" label-position="left" class="search-ad-form-item item-bottom"
                                              :rules="{required: true, message: '不能为空', trigger: 'blur'}">
                                    <el-input-number :ref="`${resolutionItem.title}_${resolutionIndex}`" v-model="resolutionItem.value1" @blur="isSaveFun"
                                                     :min="-99" :max="100" :controls="false" :precision="0" style="width: 60px;margin-right: 3px;">
                                    </el-input-number>%
                                    <span style="display: inline-block;margin: 0 6px;">~</span>
                                    <el-input-number :ref="`${resolutionItem.title}_${resolutionIndex}`" v-model="resolutionItem.value2" @blur="isSaveFun"
                                                     :min="-99" :max="100" :controls="false" :precision="0" style="width: 60px;margin: 0 3px;">
                                    </el-input-number>%
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import { adminGoodGetCategory, customer_relations } from "@/utils/apis";
    export default {
        name: "MonitoringData",
        data() {
            return {
                goodsClassList: [], //商品类目列表
                keyObjClass: {
                    label: "name",
                    value: "id",
                    children: "children",
                },
                monitoringDataRules: {},
                merchants_realTime_monitoring_data: {
                    name: "",
                    category_id: "",
                    inquiries: [
                        {
                            type: 1,
                            label: "0-6",
                            title: "inquiries",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            type: 2,
                            label: "6-12",
                            title: "inquiries",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            type: 3,
                            label: "12-18",
                            title: "inquiries",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            type: 4,
                            label: "18-24",
                            title: "inquiries",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    // 接待量
                    accommodation_was: [
                        {
                            title: "accommodation_was",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "accommodation_was",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "accommodation_was",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "accommodation_was",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    //促成下单金额
                    promoted_order_amount: [
                        {
                            title: "promoted_order_amount",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_amount",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_amount",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_amount",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    // 促成下单商品数
                    promoted_order_num: [
                        {
                            title: "promoted_order_num",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_num",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_num",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "promoted_order_num",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    //应答率
                    response_rate: [
                        {
                            title: "response_rate",
                            type: 1,
                            label: "0-6",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate",
                            type: 2,
                            label: "6-12",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate",
                            type: 3,
                            label: "12-18",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate",
                            type: 4,
                            label: "18-24",
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    //30s应答率
                    response_rate_30: [
                        {
                            title: "response_rate_30",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate_30",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate_30",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "response_rate_30",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    //评价率
                    evaluation_rate: [
                        {
                            title: "evaluation_rate",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "evaluation_rate",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "evaluation_rate",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "evaluation_rate",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                    //解决率
                    resolution_rate: [
                        {
                            title: "resolution_rate",
                            type: 1,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "resolution_rate",
                            type: 2,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "resolution_rate",
                            type: 3,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                        {
                            title: "resolution_rate",
                            type: 4,
                            type_value: void 0,
                            value1: void 0,
                            value2: void 0,
                        },
                    ],
                },
            };
        },
        mounted() {
            this.getGoodsClassList();
            if (this.$route.query.id) {
                this.getEditData();
            }
        },
        methods: {
            // 获取编辑数据
            getEditData() {
                let params = {
                    id: this.$route.query.id,
                };
                customer_relations(params)
                    .then((res) => {
                        let monitoring_data = res.data.merchants_realTime_monitoring_data;
                        // if (monitoring_data.accommodation_was){
                        //     let arr = this.merchants_realTime_monitoring_data.accommodation_was;
                        //     monitoring_data.accommodation_was.forEach((item,index)=>{
                        //         arr[index].type = item.type;
                        //         arr[index].type_value = item.value.split("#")[0];
                        //         arr[index].value1 = item.value.split("#")[1];
                        //         arr[index].value2 = item.value.split("#")[2];
                        //     })
                        // }
                        for (const key in monitoring_data) {
                            if (Array.isArray(monitoring_data[key])) {
                                if (key !== 'category_id') {
                                    monitoring_data[key].forEach((item, index) => {
                                        this.merchants_realTime_monitoring_data[key][index].type = item.type;
                                        this.merchants_realTime_monitoring_data[key][index].type_value = item.value.split("#")[0];
                                        this.merchants_realTime_monitoring_data[key][index].value1 = item.value.split("#")[1];
                                        this.merchants_realTime_monitoring_data[key][index].value2 = item.value.split("#")[2];
                                    });
                                }
                            }
                        }
                        this.merchants_realTime_monitoring_data.name = res.data.name;
                        this.merchants_realTime_monitoring_data.category_id = res.data.good_category_id;
                    })
                    .catch((err) => {
                        console.log("err", err);
                    });
            },
            //获取商品类目
            getGoodsClassList() {
               adminGoodGetCategory().then((res) => {
                    this.goodsClassList = res.data;
                }).catch((err) => {
                   console.log(err);
              });
            },
            //选择商品列表
            handleChangeGoodsClass() {
                this.getGoodsClassList();
            },
            confirmForm() {
                this.$refs["merchants_realTime_monitoring_data"].validate((valid) => {
                    if (valid) {
                        this.isSaveFun();
                        if (!this.merchants_realTime_monitoring_data.name) {
                            return this.$message.warning('请输入创建名称');
                        }
                        let name = this.merchants_realTime_monitoring_data.name.trim();
                        if (name.length < 2 || name.length > 20) {
                            return  this.$message.warning('创建名称长度在2-18个字之间');
                        }
                        if (!this.merchants_realTime_monitoring_data.category_id) {
                            return this.$message.warning('请选择商品类目');
                        }
                        //咨询量
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.inquiries.length; i++){
                            let item = this.merchants_realTime_monitoring_data.inquiries[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('咨询量每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('咨询量每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('咨询量每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('咨询量波动范围最小值不能大于最大值');
                            }
                        }
                        //接待量
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.accommodation_was.length;i++){
                            let item = this.merchants_realTime_monitoring_data.accommodation_was[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('接待量每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('接待量每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('接待量每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('接待量波动范围最小值不能大于最大值');
                            }
                        }
                        //促成下单金额
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.promoted_order_amount.length;i++){
                            let item = this.merchants_realTime_monitoring_data.promoted_order_amount[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('促成下单金额每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('促成下单金额每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('促成下单金额每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('促成下单金额波动范围最小值不能大于最大值');
                            }
                        }
                        //促成下单商品数
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.promoted_order_num.length; i++){
                            let item = this.merchants_realTime_monitoring_data.promoted_order_num[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('促成下单商品数每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('促成下单商品数每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('促成下单商品数每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('促成下单商品数波动范围最小值不能大于最大值');
                            }
                        }
                        //应答率
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.response_rate.length; i++){
                            let item = this.merchants_realTime_monitoring_data.response_rate[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('应答率每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('应答率每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('应答率每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('应答率波动范围最小值不能大于最大值');
                            }
                        }
                        //30s应答率
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.response_rate_30.length; i++){
                            let item = this.merchants_realTime_monitoring_data.response_rate_30[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('30s应答率每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('30s应答率每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('30s应答率每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('30s应答率波动范围最小值不能大于最大值');
                            }
                        }
                        //评价率
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.evaluation_rate.length; i++) {
                            let item = this.merchants_realTime_monitoring_data.evaluation_rate[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('评价率每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('评价率每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('评价率每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('评价率波动范围最小值不能大于最大值');
                            }
                        }
                        //评价率
                        for (let i = 0; i < this.merchants_realTime_monitoring_data.resolution_rate.length; i++){
                            let item = this.merchants_realTime_monitoring_data.resolution_rate[i];
                            if (item.type_value === undefined) {
                                return this.$message.warning('解决率每项为必填');
                            }
                            if (item.value1 === undefined) {
                                return this.$message.warning('解决率每项为必填');
                            }
                            if (item.value2 === undefined) {
                                return this.$message.warning('解决率每项为必填');
                            }
                            if (item.value1 > item.value2) {
                                return this.$message.warning('解决率波动范围最小值不能大于最大值');
                            }
                        }
                        this.$emit("stepActiveAdd", this.merchants_realTime_monitoring_data);
                    } else {
                        return false;
                    }
                });
            },
            // isSaveFun() {
            //     for (let key in this.merchants_realTime_monitoring_data) {
            //         console.log(key);
            //         if (key !== "name" && key !== "category_id") {
            //             this.merchants_realTime_monitoring_data[key].forEach((item,index) => {
            //                     let title = item.title;
            //                     console.log(item);
            //                     if (item.type_value === undefined ) {
            //                         this.$refs[`${title}_${index}`][0].$el.classList.add("isError");
            //                     } else {
            //                         this.$refs[`${title}_${index}`][0].$el.classList.remove("isError");
            //                     }
            //                     if (item.value1 === undefined ) {
            //                         this.$refs[`${title}_${index}`][1].$el.classList.add("isError");
            //                     } else {
            //                         this.$refs[`${title}_${index}`][1].$el.classList.remove("isError");
            //                     }
            //                     if (item.value2 === undefined) {
            //                         this.$refs[`${title}_${index}`][2].$el.classList.add("isError");
            //                     } else {
            //                         this.$refs[`${title}_${index}`][2].$el.classList.remove("isError");
            //                     }
            //                 }
            //             );
            //         }
            //     }
            // },
            isSaveFun() {
                let warning_arr = [];
                for (let key in this.merchants_realTime_monitoring_data) {
                    if (key !== 'name' && key !== 'category_id') {
                        this.merchants_realTime_monitoring_data[key].forEach(
                            (item, index) => {
                                let title = item.title;
                                if (item.type_value === undefined) {
                                    this.$refs[`${title}_${index}`][0].$el.classList.add('isError', 'required');
                                } else {
                                    this.$refs[`${title}_${index}`][0].$el.classList.remove('isError', 'required');
                                }
                                if (key==='promoted_order_num'){
                                    if(item.type_value > this.merchants_realTime_monitoring_data['promoted_order_amount'][index].type_value){
                                        this.$refs[`${title}_${index}`][0].$el.classList.add('isError');
                                        let warning_txt = '促成下单商品数不能大于促成下单金额';
                                        if (!warning_arr.includes(warning_txt)) {
                                          warning_arr.push('促成下单商品数不能大于促成下单金额');
                                        }
                                    }else {
                                        this.$refs[`${title}_${index}`][0].$el.classList.remove('isError');
                                    }
                                }
                                if (item.value1 === undefined) {
                                    this.$refs[`${title}_${index}`][1].$el.classList.add('isError', 'required');
                                } else {
                                    this.$refs[`${title}_${index}`][1].$el.classList.remove('isError', 'required');
                                }
                                if (item.value2 === undefined) {
                                    this.$refs[`${title}_${index}`][2].$el.classList.add('isError', 'required');
                                } else {
                                    this.$refs[`${title}_${index}`][2].$el.classList.remove('isError', 'required');
                                    if (item.value2 < item.value1) {
                                        this.$refs[`${title}_${index}`][2].$el.classList.add('isError');
                                        let warning_txt_1 = '波动范围最小值不能大于最大值';
                                        if (!warning_arr.includes(warning_txt_1)) {
                                          warning_arr.push(warning_txt_1);
                                        }
                                    } else {
                                        this.$refs[`${title}_${index}`][2].$el.classList.remove('isError');
                                    }
                                }
                            }
                        );
                    }
                }
                if (warning_arr.length > 0) {
                    let warning_msg = warning_arr.join('且');
                    this.$message.warning(warning_msg);
                    return;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .monitoring-data {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        .monitoring-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            label {
                font-size: 16px;
                margin-right: 20px;
            }
            .w420-cascader {
                width: 420px;
            }
        }
        .main-wrapper {
            margin-top: 34px;
            .optimize-title {
                font-size: 16px;
                color: #333;
                position: relative;
                padding: 0 0 0 16px;
                margin-bottom: 20px;
                &:before {
                    content: "";
                    position: absolute;
                    width: 4px;
                    height: 16px;
                    background: #2dc079;
                    left: 0;
                    top: 4px;
                }
            }
            .monitoring-info {
                .monitoring-input-title {
                    display: flex;
                    justify-content: flex-start;
                    margin: 10px 0;
                    .text {
                        display: block;
                        width: 270px;
                        margin-right: 40px;
                        text-align: center;
                        &:nth-child(1) {
                            margin-left: 42px;
                        }
                        &:nth-child(2) {
                            width: 195px;
                            margin-right: 46px;
                        }
                        &:nth-child(3) {
                            width: 247px;
                        }
                        &:nth-child(4) {
                            width: 247px;
                        }
                    }
                }

                .search-column {
                    display: flex;
                    justify-content: flex-start;
                    .search-column-one {
                        width: 274px;
                        min-width: 274px;
                        margin-right: 30px;
                        &:nth-child(2) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .required{
            &:after{
                display: block;
                content: '必须填';
                position: absolute;
                bottom: -30px;
                left: calc(50% - 16px);
                font-size: 14px;
                color: #ff0000;
            }
        }
    }
</style>
