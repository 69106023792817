<template>
    <div class="query-data">
        <div class="goods-data-btn">
            <div>
                <el-button plain @click="deleteGoods">删除</el-button>
                <el-button plain @click="allUnfold(1)">全部展开</el-button>
                <el-button plain @click="allUnfold(2)">全部收起</el-button>
            </div>
            <el-button type="primary" @click="addGoodsVisible=true">添加商品信息</el-button>
        </div>
        <div class="goods-data-table">
            <div class="data-table-header">
                <div class="table-header-item" :style="flexList[0]">
                    <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" style="margin-right: 6px" v-model="checkAll"></el-checkbox>
                    <span>商品信息</span>
                </div>
                <div class="table-header-item" :style="flexList[1]"><span>是否下单</span></div>
                <div class="table-header-item" :style="flexList[2]"><span>客户问题</span></div>
                <div class="table-header-item" :style="flexList[3]"><span>客服回答</span></div>
                <div class="table-header-item" :style="flexList[4]"><span>操作</span></div>
            </div>
            <div class="data-table-body">
                <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <div class="table-body-item" v-for="(item,index) in goodsMsgList">
                        <div class="body-item-top">
                            <div class="item-top-item" :style="flexList[0]">
                                <i @click="item.isShow = !item.isShow" v-show="item.isShow" class="iconfont">&#xe65f;</i>
                                <i @click="item.isShow = !item.isShow" v-show="!item.isShow" class="iconfont">&#xe65d;</i>
                                <el-checkbox style="margin-right: 6px" :key="index" :label="index"></el-checkbox>
                                <span class="text-overflow">{{goodsFormat(item.good_id)}}</span>
                            </div>
                            <div class="item-top-item" :style="flexList[1]"><span class="text-overflow">{{buyFormat(item.is_buy)}}</span></div>
                            <div class="item-top-item" :style="flexList[2]"><span></span></div>
                            <div class="item-top-item" :style="flexList[3]"><span class="text-overflow"></span></div>
                            <div class="item-top-item" :style="flexList[4]">
                                <el-link type="success" @click="editGoods(index)">编辑</el-link>
                                <el-link type="primary" @click="addIssueBtn(index)">添加问题</el-link>
                            </div>
                        </div>
                        <div class="body-item-bottom" v-for="(questionItem, questionIndex) in item.questions" v-show="item.isShow">
                            <div class="item-bottom-item" :style="flexList[0]"><span></span></div>
                            <div class="item-bottom-item" :style="flexList[1]"><span></span></div>
                            <div class="item-bottom-item" :style="flexList[2]"><span class="text-overflow">{{questionItem.questions}}</span></div>
                            <div class="item-bottom-item" :style="flexList[3]"><span class="text-overflow">{{questionItem.answer}}</span></div>
                            <div class="item-bottom-item" :style="flexList[4]">
                                <el-link type="success" @click="questionEdit(index,questionIndex)">编辑</el-link>
                                <el-link type="danger" @click="questionDelete(index,questionIndex)">删除</el-link>
                            </div>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>
        </div>
        <!--  添加添加商品信息弹框  -->
        <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                title="添加商品信息"
                custom-class="green-dialog2"
                :visible.sync="addGoodsVisible"
                @close="addGoodsResetForm"
                @opened="openAddGoods"
                width="500px">
            <el-form ref="addGoodsForm" :model="addGoodsForm" :rules="addGoodsRules" label-width="120px">
                <el-form-item label="商品" prop="good_id">
                    <el-select v-model="addGoodsForm.good_id" placeholder="请选择商品" style="width:340px;">
                        <el-option
                                v-for="item in goodsList"
                                :key="item.id"
                                :label="item.good_title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否购买" prop="is_buy">
                    <el-select v-model="addGoodsForm.is_buy" placeholder="请选择是否下单" style="width:340px;">
                        <el-option
                                v-for="item in buyList"
                                :key="item.is_buy"
                                :label="item.value"
                                :value="item.is_buy">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button  @click="addGoodsVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="saveAddGoods('addGoodsForm')">保存</el-button>
            </div>
        </el-dialog>
        <!--  添加问题弹框  -->
        <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                title="添加问题"
                custom-class="green-dialog2"
                :visible.sync="addIssueVisible"
                @close="issueResetForm"
                width="500px">
            <el-form ref="addIssueForm" :model="addIssueForm" :rules="addIssueRules" label-width="120px">
                <el-form-item label="客户问题" prop="questions">
                    <el-input type="textarea" v-model="addIssueForm.questions"></el-input>
                </el-form-item>
                <el-form-item label="客服回答" prop="answer">
                    <el-input type="textarea" v-model="addIssueForm.answer"></el-input>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button  @click="addIssueVisible = false">取 消</el-button>
                <el-button style="margin-left: 30px" @click="saveAddIssue('addIssueForm')" type="primary">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {adminGoodListGood,customer_relations} from '@/utils/apis'
    export default {
        name: "QueryData",
        data(){
            return{
                addGoodsVisible:false, // 添加商品信息弹框
                goodsList:[],//商品信息列表数据
                orderList:[],//是否购买
                isIndeterminate:false,
                cityOptions:[],
                addGoodsForm:{
                    id:'',
                    good_id:'',
                    is_buy:'',
                },
                addGoodsRules:{
                    good_id:[
                        { required: true, message: '请选择商品', trigger: ['blur', 'change']}
                    ],
                    is_buy:[
                        { required: true, message: '请选择是否下单', trigger: ['blur', 'change']}
                    ],
                },
                addIssueVisible:false, // 添加问题弹框
                addIssueIndex:'', // 添加问题索引
                addIssueForm:{
                    questions:'',//客户问题
                    answer:'',//客服回答
                    questionIndex:'',
                },
                addIssueRules:{
                    questions:[
                        { required: true, message: '请输入客户问题', trigger: ['blur', 'change']}
                    ],
                    answer:[
                        { required: true, message: '请输入客服回答', trigger: ['blur', 'change']}
                    ],
                },
                //商品信息列表
                goodsMsgList:[],
                buyList: [
                    {
                        is_buy: 0,
                        value: '未下单',
                        isShow:true
                    },
                    {
                        is_buy: 1,
                        value: '已下单',
                        isShow:true
                    }
                ],
                show:false,
                checkedCities:[],
                checkAll:false,
                flexList:[
                    {
                        flex:0.7
                    },
                    {
                        flex:0.84
                    },
                    {
                        flex:1.25
                    },
                    {
                        flex:1.25,
                        marginLeft:'20px'
                    },
                    {
                        flex:1.6,
                    },
                ],
                category_id:'',//类目id
            }
        },
        mounted() {
            if(this.$route.query.id){
                this.getEditData()
            }
        },
        methods:{
            // 获取编辑数据
            getEditData(){
                let params = {
                    id:this.$route.query.id
                }
                customer_relations(params).then((res)=>{
                    res.data.customer_inquiries_query_data.forEach((item)=>{
                        let obj = {
                            id:item.id,
                            good_id:item.good_id,
                            good_name:item.good_name,
                            is_buy:item.is_buy,
                            questions:item.question,
                            isShow:false,
                        };
                        this.goodsMsgList.push(obj);
                    })
                }).catch((err)=>{
                    console.log('err', err)
                })
            },
            // 获取商品列表
            getAdminGoodListGood(id){
                let params = {
                    category_id:id
                }
                adminGoodListGood(params).then((res)=>{
                    this.goodsList = res.data;
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            // 全部展开 收起
            allUnfold(num){
                if(num === 1){
                    this.goodsMsgList.forEach(item=>{
                        item.isShow = true;
                    })
                } else {
                    this.goodsMsgList.forEach(item=>{
                        item.isShow = false;
                    })
                }
            },
            // 添加商品信息保存按钮
            saveAddGoods(formName){
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        let obj = {
                            good_id:this.addGoodsForm.good_id,
                            is_buy:this.addGoodsForm.is_buy,
                            questions:[],
                            isShow:true,
                        }
                        if(this.addGoodsForm.id !== ''){
                            let index = this.addGoodsForm.id;
                            obj.isShow =  this.goodsMsgList[index].isShow;
                            obj.questions =  this.goodsMsgList[index].questions;
                            this.goodsMsgList[index] = obj
                        } else {
                            this.goodsMsgList.push(obj)
                        }
                        this.cityOptions = [];
                        this.goodsMsgList.forEach((item,index)=>{
                            this.cityOptions.push(index)
                        })
                        this.addGoodsVisible = false;
                        if(this.addGoodsForm.id !== ''){
                            this.$message.success('修改成功！')
                        } else {
                            this.$message.success('添加成功！')
                        }
                    } else {
                        return false;
                    }
                })
            },
            // 格式化商品id
            goodsFormat(id){
                for(let i = 0; i < this.goodsList.length; i++){
                    if(this.goodsList[i].id === id){
                        return this.goodsList[i].good_title
                    }
                }
            },
            // 格式化操是否下单id
            buyFormat(is_buy){
                for(let i = 0; i < this.buyList.length; i++){
                    if(this.buyList[i].is_buy === is_buy){
                        return this.buyList[i].value
                    }
                }
            },
            //编辑商品
            editGoods(index){
                this.addGoodsForm.id = index;
                this.addGoodsVisible = true;
            },
            // 打开添加商品信息弹框
            openAddGoods(){
                if(this.addGoodsForm.id !== ''){
                    let index = this.addGoodsForm.id;
                    this.addGoodsForm.good_id = this.goodsMsgList[index].good_id;
                    this.addGoodsForm.is_buy = this.goodsMsgList[index].is_buy;
                    this.addGoodsForm.good_id = this.goodsMsgList[index].good_id;
                }
            },
            // 关闭添加商品信息弹框
            addGoodsResetForm(){
                this.$refs.addGoodsForm.resetFields()
                this.addGoodsForm.id = '';
                this.addGoodsForm.good_id = '';
                this.addGoodsForm.is_buy = '';
            },
            //添加问题保存按钮
            saveAddIssue(formName){
                console.log(this.goodsMsgList[this.addIssueIndex])
                this.$refs[formName].validate((valid)=>{
                    if(valid){
                        let obj = {
                            questions:this.addIssueForm.questions,
                            answer : this.addIssueForm.answer,
                        }
                        if(this.addIssueForm.index !== '' && this.addIssueForm.questionIndex !== ''){
                            this.goodsMsgList[this.addIssueForm.index].questions[this.addIssueForm.questionIndex] = obj;
                            this.$message.success('修改成功');
                        } else {
                            this.goodsMsgList[this.addIssueIndex].questions.push(obj);
                            this.$message.success('添加成功');
                        }
                        this.addIssueVisible = false
                    } else {
                        return false
                    }
                })
            },
            // 关闭添加问题弹框
            issueResetForm(){
                this.$refs.addIssueForm.resetFields();
                this.addIssueForm = {
                    questionIndex:'',
                    questions:'',
                    answer:'',
                }
                this.addIssueIndex = '';
            },
            //删除商品信息
            deleteGoods(){
                if(this.checkedCities.length){
                    this.$confirm(`是否删除所选商品信息，删除将无法恢复`, '删除', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        customClass:'green-message-box',
                        type:'warning'
                    }).then(()=>{
                        // this.checkedCities.sort();
                        for(let i = this.checkedCities.length-1; i >= 0; i--){
                            this.goodsMsgList.splice(this.checkedCities[i],1)
                        }
                        this.checkedCities = [];
                        this.isIndeterminate = false;
                        this.cityOptions = []
                        this.goodsMsgList.map((item,index)=>{
                            this.cityOptions.push(index)
                        })
                    }).catch(()=>{
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    })
                } else {
                    this.$message.warning('请选择所要删除的商品信息')
                }

            },
            //全选
            handleCheckAllChange(val){
                this.checkedCities = val ? this.cityOptions : [];
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value){
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.cityOptions.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.cityOptions.length;
            },
            // 新增问题按钮
            addIssueBtn(index){
                this.addIssueIndex = index;
                this.addIssueVisible = true;
            },
            //编辑问题
            questionEdit(index,questionIndex){
                this.addIssueVisible = true;
                this.addIssueForm.index = index;
                this.addIssueForm.questionIndex = questionIndex;
                this.addIssueForm.questions = this.goodsMsgList[index].questions[questionIndex].questions;
                this.addIssueForm.answer = this.goodsMsgList[index].questions[questionIndex].answer;
            },
            // 删除问题
            questionDelete(index,questionIndex){
                this.$confirm(`是否删除该问题，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    this.goodsMsgList[index].questions.splice(questionIndex,1);
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            confirmForm(){
                if(this.goodsMsgList.length){
                    for(let i = 0; i < this.goodsMsgList.length; i++){
                        if(this.goodsMsgList[i].questions.length < 1){
                            this.$message.warning('每个商品最少添加一个问题');
                            return
                        }
                    }
                    let goods = JSON.parse(JSON.stringify(this.goodsMsgList));
                    for(let i = 0; i < goods.length; i++){
                        delete goods[i].isShow
                    }
                    this.$emit('stepActiveAdd',goods)
                } else {
                    this.$message.warning('请添加最少一个商品')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .query-data{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        .goods-data-btn{
            display: flex;
            justify-content: space-between;
        }
        .goods-data-table{
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .data-table-header{
                padding: 0 20px 0 20px;
                display: flex;
                background: #EEEEEE;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;
                span{
                    color: #666666;
                    font-size: 16px;
                }
                .table-header-item{
                    flex: 1;
                    width: 1%;
                    box-sizing: border-box;
                    &:nth-child(2){
                        span{
                            margin-left: 16px;
                        }
                    }
                    &:nth-child(3),&:nth-child(6),&:last-of-type{
                        text-align: center;
                    }
                    &:nth-child(5){
                        span{
                            margin-left: 40px;
                        }
                    }
                }
            }
            .data-table-body{
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .table-body-item{
                    display: flex;
                    flex-direction: column;
                    .body-item-top, .body-item-bottom{
                        padding: 0 20px 0 20px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #EEEEEE;
                        .item-top-item, .item-bottom-item{
                            width: 1%;
                            flex: 1;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            &:nth-child(2){
                                span{
                                    margin-left: 16px;
                                }
                            }
                            i{
                                line-height: 1;
                                cursor: pointer;
                                font-size: 18px;
                                color: #7D7D7D;
                                margin-right: 4px;
                            }
                            span{
                                //flex: 1;
                                font-size: 16px;
                                color: #333;
                            }
                            &:nth-child(3),&:nth-child(5),&:last-of-type{
                                justify-content: center;
                            }
                        }
                    }
                }
            }
        }
        ::v-deep.el-checkbox{
            .el-checkbox__label{
                display: none;
            }
        }
        ::v-deep .green-dialog2{
            .el-dialog__header{
                padding: 16px 24px;
                background: #2DC079;
                span,i{
                    font-size: 16px;
                    line-height: 1;
                    color: #fff;
                }
            }
            .el-dialog__body{
                padding:22px 24px 22px 0px;
                // 带表格的
                .el-form-item{
                    margin-bottom: 24px;
                }
                .el-form-item__label{
                    font-size: 16px;
                    color: #666666;
                    //margin-bottom: 10px;
                }
                .dialog-footer{
                    margin-top: 30px;
                    text-align: center;
                }
            }
        }
        ::v-deep .el-textarea__inner{
            height: 120px;
            resize: none
        }
    }
</style>