<template>
    <div class="independent-sales">
        <div class="add-btn-left">
            <el-button type="primary" @click="addSales()">添加商品信息</el-button>
        </div>
        <el-table :data="saleManagementContent" style="width: 100%; flex: 1;border: 1px solid #EEE;" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '12px',color: '#333',height: '70px'}">
            <el-table-column prop="goods_id" label="商品信息">
                <template slot-scope="scope">
                    <span>{{goodsFormat(scope.row.good_id)}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="sales_price" label="单价" align="center"></el-table-column>
            <el-table-column prop="good_num" label="申请数量" align="center"></el-table-column>
            <el-table-column label="客服期望及原因" align="center">
                <template slot-scope="scope">
                    <p>{{customerFormat(scope.row.customer_expectation)}}</p>
                    <p style="color:#999">{{applyReasonFormat(scope.row.apply_reason)}}</p>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center"  width="200">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="editSales(scope.row,scope.$index)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="deleteSales(scope.$index)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="salesTitle" @close="resetForm()"
                   custom-class="green-dialog02" :visible.sync="salesDialog" width="500px">
            <el-form :model="salesForm" ref="salesForm"  :rules="addSalesRules" :hide-required-asterisk="true" label-position="right" label-width="100px">
                <el-form-item label="商品" prop="good_id">
                    <el-select v-model="salesForm.good_id" placeholder="请选择商品" style="width:340px;" @change="goodsChange">
                        <el-option
                                v-for="item in goodsList"
                                :key="item.id"
                                :label="item.good_title"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="good_num" label="商品数量">
                    <el-input-number :controls="false"  v-model="salesForm.good_num" :min="1" :max="9999999999"  placeholder="请选择客商品数量"
                                     :precision="0"
                                     style="width: 340px">
                    </el-input-number>
                </el-form-item>
                <el-form-item prop="customer_expectation" label="客户期望">
                    <el-select v-model="salesForm.customer_expectation" placeholder="请选择客户期望" style="width:340px;">
                        <el-option
                                v-for="item in customerExpectationList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="apply_reason" label="申请原因">
                    <el-select v-model="salesForm.apply_reason" placeholder="请选择申请原因" style="width:340px;">
                        <el-option
                                v-for="item in applyReasonList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="salesDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="confirmForms('salesForm')" >确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {adminGoodListGood,adminGoodGetGood,customer_relations} from '@/utils/apis'
    export default {
        name: "IndependentSales",
        data(){
            return{
                goodsList:[],//商品信息列表数据
                salesTableIndex:[],
                saleManagementContent:[],//商品信息内容
                salesTitle:'',//弹窗标题
                salesDialog:false,//弹窗
                salesForm:{
                    good_id:'',//商品信息
                    good_num:void 0,//申请数量
                    customer_expectation:'',//客户期望
                    apply_reason:'',//申请原因
                    sales_price:'',
                },
                addSalesRules:{
                    good_id:[{ required: true, message: '请选择商品', trigger: ['blur', 'change'] }],
                    good_num:[{ required: true, message: '请输入申请数量', trigger: ['blur', 'change'] }],
                    customer_expectation:[{ required: true, message: '请选择客户期望', trigger: ['blur', 'change'] }],
                    apply_reason:[{ required: true, message: '请选择申请原因', trigger: ['blur', 'change'] }],
                },
                // 客户期望数据
                customerExpectationList:[
                    {
                        id: 1,
                        name: '退货',
                    },
                    {
                        id: 2,
                        name: '换货',
                    },
                    {
                        id: 3,
                        name: '维修',
                    },
                    {
                        id: 4,
                        name: '补发商品',
                    },
                ],
                // 申请原因数据
                applyReasonList:[
                    {
                        id: 1,
                        name: '产品质量',
                    },
                    {
                        id: 2,
                        name: '7天无理由退',
                    },
                    {
                        id: 3,
                        name: '误购',
                    },
                    {
                        id: 4,
                        name: '物流损',
                    },
                    {
                        id: 5,
                        name: '其他',
                    },
                    {
                        id: 6,
                        name: '漏发商品',
                    },
                    {
                        id: 7,
                        name: '缺少配件',
                    },
                    {
                        id: 8,
                        name: '过保',
                    },
                    {
                        id: 9,
                        name: '人为损坏',
                    },
                    {
                        id: 10,
                        name: '发错货',
                    },
                    {
                        id: 11,
                        name: '取消订单',
                    },
                    {
                        id: 12,
                        name: '特色服务',
                    },
                    {
                        id: 13,
                        name: '产品误导',
                    },
                    {
                        id: 14,
                        name: '外观原因',
                    },
                    {
                        id: 15,
                        name: '假货/旧货',
                    },
                    {
                        id: 16,
                        name: '价格保护',
                    },
                    {
                        id: 17,
                        name: '软性故障',
                    },
                    {
                        id: 18,
                        name: '开箱残',
                    },
                    {
                        id: 19,
                        name: '30天无理由',
                    },
                    {
                        id: 20,
                        name: '15天无理由',
                    },
                    {
                        id: 21,
                        name: 'pop备件库销毁（限热点）',
                    },
                ]
            }
        },

        mounted() {
            if(this.$route.query.id){
                this.getEditData()
            }
        },
        methods:{
            // 获取编辑数据
            getEditData(){
                let params = {
                    id:this.$route.query.id
                }
                customer_relations(params).then((res)=>{
                    this.saleManagementContent = res.data.self_sales_data;
                }).catch((err)=>{
                    console.log('err', err)
                })
            },
            //获取商品列表
            getAdminGoodListGood(id){
                let params = {
                    category_id:id
                }
                adminGoodListGood(params).then((res)=>{
                    this.goodsList = res.data;
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            goodsChange(val){
                let params = {
                    id: val
                }
                adminGoodGetGood(params).then((res) => {
                    this.salesForm.sales_price=res.data.sales_price
                })
            },
            // 格式化商品id
            goodsFormat(id){
                for(let i = 0; i < this.goodsList.length; i++){
                    if(this.goodsList[i].id === id){
                        return this.goodsList[i].good_title
                    }
                }
            },
            // 格式化操客户期望
            customerFormat(id){
                for(let i = 0; i < this.customerExpectationList.length; i++){
                    if(this.customerExpectationList[i].id === id){
                        return this.customerExpectationList[i].name
                    }
                }
            },
            // 格式化操申请原因
            applyReasonFormat(id){
                for(let i = 0; i < this.applyReasonList.length; i++){
                    if(this.applyReasonList[i].id === id){
                        return this.applyReasonList[i].name
                    }
                }
            },
            //添加商品信息弹窗
            addSales(){
                this.salesTitle='添加商品信息';
                this.salesDialog=true;
                this.salesTableIndex = -1;
            },
            //确定
            confirmForms(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let ob = {
                            good_id:this.salesForm.good_id,
                            good_num:this.salesForm.good_num,
                            customer_expectation:this.salesForm.customer_expectation,
                            apply_reason:this.salesForm.apply_reason,
                            sales_price:this.salesForm.sales_price,
                        }
                        if (this.salesTableIndex>-1){
                            //    编辑
                            this.saleManagementContent[this.salesTableIndex] = ob;
                        }else{
                            //    新增
                            this.saleManagementContent.push(ob);
                            this.$message.success('添加成功');
                        }
                        this.resetForm()
                        this.salesDialog=false;
                    } else {
                        return false;
                    }
                });
            },
            // 编辑
            editSales(row,index){
                this.salesTitle='编辑商品信息';
                this.salesDialog=true
                this.salesForm.good_id= row.good_id;
                this.salesForm.good_num= row.good_num;
                this.salesForm.customer_expectation= row.customer_expectation;
                this.salesForm.apply_reason= row.apply_reason;
                this.salesForm.sales_price= row.sales_price;
                this.salesTableIndex = index;
            },
            //删除
            deleteSales(index){
                this.$confirm(`是否删除所选商品信息，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    this.saleManagementContent.splice(index, 1);
                    this.$message.success('添加成功');
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            // 重置表单
            resetForm(){
                this.$refs.salesForm.resetFields();
                this.salesForm = this.$options.data().salesForm;
            },
            confirmForm(){
                if(this.saleManagementContent.length){
                    let goods = JSON.parse(JSON.stringify(this.saleManagementContent));
                    this.$emit('stepActiveAdd',goods)
                } else {
                    this.$message.warning('请添加最少一个商品')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .independent-sales{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        .add-btn-left{
            display: flex;
            justify-content: flex-end;
            padding-bottom: 20px;
        }
        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
        .dialog-footer{
            margin-top: 30px;
            text-align: center;
        }
    }
</style>