<template>
    <div class="hot-goods">
        <div class="hot-goods-btn">
            <el-button type="primary" @click="addGoodsBtn">添加商品</el-button>
        </div>
        <div class="hot-goods-table">
            <div class="goods-table-header">
                <div class="table-header-item" :style="styleFlex[0]"><span>商品信息</span></div>
                <div class="table-header-item" :style="styleFlex[1]"><span>30天销售量</span></div>
                <div class="table-header-item" :style="styleFlex[2]"><span>销售价</span></div>
                <div class="table-header-item" :style="styleFlex[3]"><span>操作</span></div>
            </div>
            <div class="goods-table-body">
                <div class="table-body-item" v-for="(item,index) in good_data">
                    <div class="body-item-top">
                        <div class="item-top-item" :style="styleFlex[0]">
                            <el-select v-model="item.good_id" placeholder="请选择商品" :ref="`good_id_${index}`" @change="selectGoodsChange">
                                <el-option
                                        v-show="isSelectGoods(item.id)"
                                        v-for="item in goodsList"
                                        :key="item.id"
                                        :label="item.good_title"
                                        :value="item.id">
                                </el-option>
                                <el-option :value="0" label="暂无数据" v-if="goodsChooseList.length === goodsList.length" :disabled="true"></el-option>
                            </el-select>
                        </div>
                        <div class="item-top-item" :style="styleFlex[1]">
                            <el-input-number :ref="`sales_30_${index}`" :controls="false" v-model="item.sales_30" :precision="0" :min="1"></el-input-number>
                        </div>
                        <div class="item-top-item" :style="styleFlex[2]">
                            <el-input-number :ref="`price_${index}`" :controls="false" v-model="item.price" :precision="0" :min="1"></el-input-number>
                        </div>
                        <div class="item-top-item" :style="styleFlex[3]">
                            <el-link type="danger" @click="deleteBtn(index)">删除</el-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {adminGoodListGood,customer_relations} from '@/utils/apis'
    export default {
        name: "HotGoods",
        data(){
            return{
                good_data:[], //设置商品数据
                goodsList:[], // 商品列表
                goodsChooseList:[],
                styleFlex:[
                    {
                        flex:0.5,
                    },
                    {
                        flex:1.25,
                    },
                    {
                        flex:1.25,
                    },
                    {
                        flex:0.5
                    },
                ],
            }
        },
        mounted() {
            if(this.$route.query.id){
                this.getEditData()
            }
        },
        methods:{
            // 获取编辑数据
            getEditData(){
                let params = {
                    id:this.$route.query.id
                }
                customer_relations(params).then((res)=>{
                    this.good_data = res.data.hot_goods_data;
                    this.good_data.forEach((item)=>{
                        this.goodsChooseList.push(item.good_id)
                    });
                }).catch((err)=>{
                    console.log('err', err)
                })
            },
            getAdminGoodListGood(id){
                let params = {
                    category_id:id
                }
                adminGoodListGood(params).then((res)=>{
                    this.goodsList = res.data;
                }).catch((err)=>{
                    console.log('err',err)
                })
            },
            // 筛选商品 去掉已经选择的
            selectGoodsChange(val){
                this.goodsChooseList = []
                this.good_data.forEach(item=>{
                    if(item.good_id){
                        this.goodsChooseList.push(item.good_id)
                    }
                })
            },
            isSelectGoods(id){
                if(this.goodsChooseList.includes(id)){
                    return false
                } else {
                    return true
                }
            },
            //添加商品
            addGoodsBtn(){
                let obj = {
                    good_id:'', // 选择的商品id
                    sales_30:void 0, //30天销量
                    price:void 0, //销售价
                }
                this.good_data.push(obj)
            },
            //删除
            deleteBtn(index){
                this.$confirm(`是否删除该商品，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type:'warning'
                }).then(()=>{
                    this.good_data.splice(index,1);
                    this.goodsChooseList = []
                    this.good_data.forEach(item=>{
                        if(item.good_id){
                            this.goodsChooseList.push(item.good_id)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            confirmForm(){
                if(this.good_data.length){
                    let isErrNum = 0;
                    this.good_data.forEach((item,index)=>{
                        if(!item.good_id){
                            this.$refs[`good_id_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`good_id_${index}`][0].$el.classList.remove('isError');
                        }
                        if(!item.sales_30){
                            this.$refs[`sales_30_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`sales_30_${index}`][0].$el.classList.remove('isError');
                        }
                        if(!item.price){
                            this.$refs[`price_${index}`][0].$el.classList.add('isError');
                            isErrNum = 1;
                        } else {
                            this.$refs[`price_${index}`][0].$el.classList.remove('isError');
                        }
                    })
                    if(isErrNum === 0){
                        let good_data = JSON.parse(JSON.stringify(this.good_data));
                        good_data.forEach(item=>{
                            this.goodsList.forEach(item2=>{
                                if(item.good_id === item2.id){
                                    item.good_name = item2.good_title
                                }
                            })
                        })
                        this.$emit('stepActiveAdd',good_data)
                    } else {
                        this.$message.warning('每项为必填！')
                    }
                } else {
                    this.$message.warning('请先添加至少一个商品')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .hot-goods{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        .hot-goods-btn{
            text-align: right;
        }
        .hot-goods-table{
            border: 1px solid #EEEEEE;
            box-sizing: border-box;
            width: 100%;
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            .goods-table-header{
                padding: 0 30px;
                display: flex;
                background: #EEEEEE;
                height: 60px;
                line-height: 60px;
                box-sizing: border-box;
                span{
                    color: #666666;
                    font-size: 16px;
                }
                .table-header-item{
                    text-align: center;
                    flex: 1;
                    width: 1%;
                    &:first-of-type{
                        text-align: left;
                    }
                }
            }
            .goods-table-body{
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                .table-body-item{
                    display: flex;
                    flex-direction: column;
                    .body-item-top{
                        padding: 0 30px;
                        box-sizing: border-box;
                        width: 100%;
                        display: flex;
                        border-bottom: 1px solid #EEEEEE;
                        .item-top-item, .item-bottom-item{
                            width: 1%;
                            flex: 1;
                            height: 70px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            ::v-deep .el-date-editor{
                                .el-input__inner{
                                    padding: 0 10px;
                                }
                                .el-input__prefix{
                                    display: none;
                                }
                            }
                            //&.item-bottom-item:last-of-type{
                            //  justify-content: flex-end;
                            //  .el-link{
                            //    margin-right: 6px;
                            //  }
                            //}
                        }
                    }
                }
            }
        }
    }

</style>