<template>
    <el-scrollbar class="main-wrapper">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
            <el-breadcrumb-item>客户关系管理</el-breadcrumb-item>
            <el-breadcrumb-item>{{stepActive === 1 ? '添加商家实时监控数据' :
                stepActive === 2 ? '添加客户咨询查询数据' :
                stepActive === 3 ? '添加自主售后数据' :
                stepActive === 4 ? '添加在售商品管理数据' :
                stepActive === 5 ? '添加热门商品数据' : '创建成功'}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper steps-mini">
            <div class="step-item" :class="{current: [1, 2,3,4,5,6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">添加商家实时监控数据</div>
            </div>
            <div class="step-item" :class="{current: [2,3,4,5,6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加客户咨询查询数据</div>
            </div>
            <div class="step-item" :class="{current: [3,4,5,6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">添加自主售后数据</div>
            </div>
            <div class="step-item" :class="{current: [4,5,6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">4</div>
                <div class="step-title">添加在售商品管理数据</div>
            </div>
            <div class="step-item" :class="{current: [5,6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">5</div>
                <div class="step-title">添加热门商品数据 </div>
            </div>
            <div class="step-item" :class="{current: [6].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">6</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <MonitoringData  @stepActiveAdd="stepActiveAdd" ref="MonitoringData" v-show="stepActive === 1" />
        <QueryData @stepActiveAdd="stepActiveAdd" ref="QueryData"  v-show="stepActive === 2" />
        <IndependentSales @stepActiveAdd="stepActiveAdd" ref="IndependentSales" v-show="stepActive === 3" />
        <SellingGoods @stepActiveAdd="stepActiveAdd" ref="SellingGoods" v-show="stepActive === 4" />
        <HotGoods @stepActiveAdd="stepActiveAdd" ref="HotGoods" v-show="stepActive === 5" />
        <div class="creating-success" v-if="stepActive === 6">
            <div class="success-img">
                <img src="../../../assets/images/admin/success.png" alt="">
                <p class="text">创建成功</p>
<!--                <el-button @click="prevBtn(stepActive)" type="primary" v-if="stepActive === 2">返回列表</el-button>-->
            </div>
        </div>
        <div class="bottom-btn">
            <el-button @click="prevBtn(stepActive)" v-if="stepActive !== 6">上一步</el-button>
            <el-button @click="nextBtn(stepActive)" type="primary" v-if="stepActive !== 6">下一步</el-button>
            <el-button @click="prevBtn(stepActive)" type="primary" v-else>返回列表</el-button>
        </div>
    </el-scrollbar>
</template>

<script>
    import {customer_relations,customer_relations_save} from '@/utils/apis'
    import MonitoringData from '@/components/admin/customerManagement/MonitoringData'
    import QueryData from '@/components/admin/customerManagement/QueryData'
    import IndependentSales from '@/components/admin/customerManagement/IndependentSales'
    import SellingGoods from '@/components/admin/customerManagement/SellingGoods'
    import HotGoods from '@/components/admin/customerManagement/HotGoods'
    export default {
        name: "AddRelationshipManagement",
        components: {
            MonitoringData,
            QueryData,
            IndependentSales,
            SellingGoods,
            HotGoods,
        },
        data(){
            return{
                stepActive: 1, //步骤
                // 第一步的数据
                stepOneData:null,
                // 第二步的数据
                stepTwoData:null,
                // 第三步的数据
                stepThreeData:null,
                // 第四步的数据
                stepFourData:null,
                // 第五步的数据
                stepFiveData:null,
                category_id:null,//分类
            }
        },
        mounted() {

        },
        methods:{
            // 保存
            stepActiveAdd(data){
                if(this.stepActive === 1){
                    this.stepOneData = data;
                    this.stepActive++;
                    if (isNaN(this.stepOneData.category_id)){
                        this.category_id = this.stepOneData.category_id[this.stepOneData.category_id.length - 1];
                    }else {
                        this.category_id = this.stepOneData.category_id;
                    }
                    this.$refs.QueryData.getAdminGoodListGood(this.category_id)
                } else if(this.stepActive === 2){
                    this.stepTwoData = data;
                    this.stepActive++;
                    this.$refs.IndependentSales.getAdminGoodListGood(this.category_id)
                } else if(this.stepActive === 3){
                    this.stepThreeData = data;
                    this.stepActive++;
                    this.$refs.SellingGoods.getAdminGoodListGood(this.category_id)
                }else if(this.stepActive === 4){
                    this.stepFourData = data;
                    this.stepActive++;
                    this.$refs.HotGoods.getAdminGoodListGood(this.category_id)
                }else if(this.stepActive === 5){
                    this.stepFiveData = data;
                    let monitoring_data = this.$refs.MonitoringData.merchants_realTime_monitoring_data;
                    let monitoring_data_obj = {};
                    for (const key in monitoring_data){
                        if (Array.isArray(monitoring_data[key])){
                            if (key === 'category_id'){
                                monitoring_data_obj[key] = monitoring_data[key]
                            }else{
                                monitoring_data_obj[key] = [];
                                monitoring_data[key].forEach((item)=>{
                                    let obj = {
                                        type:item.type,
                                        value:item.type_value+'#'+item.value1+'#'+item.value2
                                    }
                                    monitoring_data_obj[key].push(obj)
                                })
                            }
                        }else{
                            monitoring_data_obj[key] = monitoring_data[key]
                        }
                    }
                    let goodsMsgList = this.$refs.QueryData.goodsMsgList;
                    for (let i in goodsMsgList){
                        delete this.$refs.QueryData.goodsMsgList[i].isShow
                    }
                    let param =  {
                        name:this.stepOneData.name,
                        good_category_id:this.category_id,
                        merchants_realTime_monitoring_data:JSON.stringify(monitoring_data_obj),
                        customer_inquiries_query_data:JSON.stringify(goodsMsgList),
                        self_sales_data:JSON.stringify(this.$refs.IndependentSales.saleManagementContent),
                        selling_goods_data:JSON.stringify(this.$refs.SellingGoods.good_data),
                        hot_goods_data:JSON.stringify(this.$refs.HotGoods.good_data),
                    }
                    if (this.$route.query.id) {
                        param.id = this.$route.query.id;
                    }
                    customer_relations_save(param).then((res)=>{
                        this.$message.success(res.msg);
                        this.stepActive++
                    }).catch((err)=>{
                        console.log('err', err)
                    })
                }
            },
            //下一步按钮
            nextBtn(){
                if(this.stepActive === 1){
                    this.$refs.MonitoringData.confirmForm();
                } else if(this.stepActive === 2){
                    this.$refs.QueryData.confirmForm();
                } else if(this.stepActive === 3){
                    this.$refs.IndependentSales.confirmForm();
                }else if(this.stepActive === 4){
                    this.$refs.SellingGoods.confirmForm();
                }else if(this.stepActive === 5){
                    this.$refs.HotGoods.confirmForm();
                }
            },
            // 上一步按钮
            prevBtn(index) {
                if (index === 1) {
                    this.$router.go(-1)
                } else if (index === 6) {
                    this.$router.push('/practice/customerRelationshipManagement')
                } else {
                    this.stepActive = index - 1
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper{
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .bottom-btn {
        text-align: center;
        margin-bottom: 20px;
        /*margin-top: 50px;*/
    }
    .creating-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 120px;
        img {
            width: 240px;
        }
        .text {
            color: #333333;
            font-size: 24px;
            line-height: 1;
            margin: 32px 0 36px;
            text-align: center;
        }
    }
</style>